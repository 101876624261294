<template>
    <div class="admin-user page-container flex-column">
        <!-- search-box -->
        <el-form
            class="searchForm"
            ref="searchForm"
            :model="searchData"
            :inline="true"
            @keyup.enter.native.prevent="searchHandle"
        >
            <el-form-item label="cp名称:" prop="user_name">
                <el-select
                    v-model="searchData.user_name"
                    @change="searchHandle"
                    filterable
                    clearable
                    placeholder="请选择"
                    style="width: 180px"
                >
                    <el-option
                        v-for="(item, index) in cpList"
                        :key="item.user_id"
                        :label="item.user_name"
                        :value="item.user_name"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="searchHandle">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="showDialog">添加</el-button>
            </el-form-item>
        </el-form>

        <el-table
            class="admin-user-table"
            ref="table"
            :data="tableList"
            border
            style="width: 100%"
            v-loading="isloading"
        >
            <el-table-column label="账号id" prop="user_id" align="center">
                <template slot-scope="scope">
                    {{ scope.row.user_id }}
                </template>
            </el-table-column>
            <el-table-column
                label="cp名称"
                prop="user_name"
                align="center"
            ></el-table-column>

            <el-table-column label="开始时间" prop="start_time" align="center">
                <template slot-scope="scope">
                    {{ scope.row.start_time | timeFilter("yyyy-MM-dd") }}
                </template>
            </el-table-column>

            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="dialogHandle(scope.row)"
                        >修改</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <paginationPlus
            :currentPage.sync="pagination.pageIndex"
            :pageSize.sync="pagination.pageSize"
            :total="pagination.total"
            @callback="pageChangeHandle"
        ></paginationPlus>

        <dialogPlus
            :visible.sync="dialogData.visible"
            :title.sync="dialogData.title"
            width="700px"
            :heightFull="heightFull"
            @close="dialogCloseHandle"
        >
            <div align="center">
                <el-form
                    class="formData"
                    :model="formData"
                    :inline="true"
                    @keyup.enter.native.prevent="searchHandle"
                >
                    <el-form-item
                        label="cp名称:"
                        prop="user_id"
                        style="margin-top: 20px"
                    >
                        <el-select
                            v-model="formData.user_id"
                            filterable
                            clearable
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="(item, index) in cpList"
                                :key="item.user_id"
                                :label="item.user_name"
                                :value="item.user_id"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <br />

                    <el-form-item
                        label="开始时间:"
                        prop="start_time"
                        style="margin-top: 20px"
                    >
                        <el-date-picker
                            type="date"
                            :editable="false"
                            value-format="yyyy-MM-dd"
                            v-model="formData.start_time"
                            placeholder="选择日期时间"
                        ></el-date-picker>
                    </el-form-item>

                    <br />
                </el-form>
            </div>
            <div slot="footer" align="center">
                <template>
                    <el-button @click="dialogCloseHandle">取 消</el-button>
                    <el-button type="primary" @click="formHandle"
                        >确 定</el-button
                    >
                </template>
            </div>
        </dialogPlus>
    </div>
</template>

<script>
/*
 * @Author: yangjian
 * @Date: 2024-01-17 10:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2024-01-17 10:17:44
 * @Description: cp管理列表
 * @Company: 成都二次元动漫
 */
import { searchMixin, paginationMixin, dialogMixin, formMixin } from "@/mixins";
import { Message } from "element-ui/lib";
import moment from 'moment';
export default {
    mixins: [searchMixin, paginationMixin, dialogMixin, formMixin],
    data() {
        return {
            isloading: false,

            heightFull: false,
            searchData: {
                user_name: null,
            },
            formData: {
                user_id: null,
                start_time: null,
            },
            tableList: [],
            cpList: [],
        };
    },
    computed: {
        rowList() {
            return this.allSelect.map((power) => power);
        },
    },
    created() {
        this.pagination.pageSize = 20;
        this.getTableList();
        this.getCpList();
    },
    mounted() {},
    methods: {
        // 搜索
        searchHandle() {
            this.pagination.pageIndex = 1;
            this.getTableList();
        },
        // 显示弹窗
        showDialog() {
            this.dialogData.title = "修改开始时间";
            this.dialogData.visible = true;
            this.formData.user_id = null;
            this.formData.start_time = null;
        },
        dialogHandle(row) {
            this.showDialog();
            this.formData.user_id = row.user_id;
            this.formData.start_time = moment(row.start_time).format("YYYY-MM-DD");
        },
        // cp下拉选项
        getCpList() {
            this.$api("getcplist").then((res) => {
                this.cpList = res.data;
                this.getTableList();
            });
        },
        // 查询列表
        getTableList() {
            this.isloading = true;
            this.$api("getcpconfiglist", {
                ...this.searchData,
                page: this.pagination.pageIndex,
                size: this.pagination.pageSize,
            })
                .then((res) => {
                    this.$refs.table &&
                        (this.$refs.table.bodyWrapper.scrollTop = 0);
                    const { items, page, size, totalItems } = res.data;
                    this.tableList = items;

                    this.pagination.pageIndex = page;
                    this.pagination.pageSize = size;
                    this.pagination.total = totalItems;
                    this.isloading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.isloading = false;
                });
        },

        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        },
        // 修改
        formHandle() {
            if (!this.formData.user_id) {
                Message({
                    type: "warning",
                    message: "请选择cp名称",
                    duration: "1000",
                });
                return;
            }

            if (!this.formData.start_time) {
                Message({
                    type: "warning",
                    message: "请选择开始时间",
                    duration: "1000",
                });
                return;
            }

            return this.$api("addoredit", {
                user_id: this.formData.user_id,
                start_time: this.formData.start_time,
            }).then((res) => {
                this.dialogData.visible = false;
                this.getTableList();
            });
        },
    },
};
</script>

<style lang="scss">
@import "../../../../styles/var";
.admin-user {
    &-status {
        &::before {
            display: inline-block;
            content: " ";
            width: 3px;
            height: 6px;
            border-radius: 3px;
            margin-right: 10px;
            vertical-align: middle;
        }
        &.enabled::before {
            background: $color-success;
        }
        &.disable:before {
            background: $color-danger;
        }
    }
    &-option {
        height: 28px;
        align-items: center;
    }
    &-table {
        flex-grow: 1;
        .el-table__body-wrapper {
            height: calc(100% - 40px);
            position: relative;
            overflow-y: auto;
        }
    }
    .search-input {
        width: 524px;
    }
}
</style>
